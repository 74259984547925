import React from 'react'

import ShopButton from './ShopButton'

import toothBuddyCover from '../images/tooth_buddy_cover.jpg'

const listings = [
    {
        name: "Amazon",
        link: "https://www.amazon.com/Tooth-Buddy-Golden-Toothbrush-Weber-ebook/dp/B0B27C4449/ref=sr_1_2?keywords=tooth+buddy+and+the+golden+toothbrush&qid=1658872616&sprefix=tooth+buddy+%2Caps%2C88&sr=8-2",
    },
    {
        name: "Target",
        link: "https://www.target.com/p/tooth-buddy-and-the-golden-toothbrush-by-kurt-weber-dds-hardcover/-/A-87439172"
    },
    {
        name: "Barnes & Noble",
        link: "https://www.barnesandnoble.com/w/tooth-buddy-and-the-golden-toothbrush-kurt-weber-dds/1141948637",
    },
    {
        name: "Books-A-Million",
        link: "https://www.booksamillion.com/search?filter=&id=8617018290998&query=Tooth+Buddy+and+the+Golden+Toothbrush",
    },
    {
        name: "Thriftbooks",
        link: "https://www.thriftbooks.com/w/tooth-buddy-and-the-golden-toothbrush/37278204/#edition=65277621&idiq=54047013",
    }
]

const AmazonListing = () => {
    return (
        <div className="flex flex-row justify-center">
            <div className='bg-white w-96 sm:w-auto mx-10 mt-4 sm:mt-10 p-5 rounded-lg drop-shadow-lg mb-4'>
                <div className='flex flex-col items-center pb-2'>
                    <p className='text-3xl font-bold text-center'>Tooth Buddy and the Golden Toothbrush</p>
                    <p className='text-xl'>Available Now!</p>

                </div>
                <div className='flex flex-col sm:flex-row justify-between sm:items-center px-6'>
                    <div className='flex justify-center'>
                        <img className="w-48 border-black border-2" src={toothBuddyCover} alt="Tooth Buddy and the Golden Toothbrush book cover" />
                    </div>
                    <div className='flex flex-col space-y-2'>

                        {listings.map((data) => (
                            <ShopButton name={data.name} link={data.link} />
                        ))}

                        {/* <a href={amazonLink}>
                            <div className='flex flex-row bg-orange-300 rounded-md border-black w-32 justify-center mt-4 sm:mt-0'>
                                <text className='text-lg font-semibold p-1'>Shop Amazon</text>
                            </div>
                        </a>
                        <a href={targetLink}>
                            <div className='flex flex-row bg-orange-300 rounded-md border-black w-32 justify-center mt-4 sm:mt-0'>
                                <text className='text-lg font-semibold p-1'>Shop Target</text>
                            </div>
                        </a> */}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AmazonListing