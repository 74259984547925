import React from 'react'

const ShopButton = ({name, link}) => {
    return (
        <div>
            <a href={link}>
                <div className='flex flex-row bg-orange-300 rounded-md border-black w-64 justify-center mt-4 sm:mt-0'>
                    <p className='text-lg font-semibold p-1'>Shop {name}</p>
                </div>
            </a>
        </div>
    )
}

export default ShopButton